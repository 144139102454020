import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

type Breadcrumb = { label: string; url: string }

const getBreadcrumb = (breadcrumbs: Breadcrumb[], endpoint) => {
  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: breadcrumbs.map(({ label, url }, index) => ({
      "@type": "ListItem",
      position: index + 1,
      name: label,
      item: `${endpoint}${url}`,
    })),
  }
}

const SEO: React.FC<{
  breadcrumbs?: Array<Breadcrumb>
  description: string
  title: string
  image?: string
  index?: boolean
}> = props => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            image
            social {
              twitter
            }
          }
        }
      }
    `
  )

  const siteUrl = site.siteMetadata?.siteUrl

  return (
    <Helmet
      htmlAttributes={{
        lang: "fr",
      }}
      title={props.title}
      meta={[
        {
          name: "charset",
          content: "UTF-8",
        },
        {
          name: `description`,
          content: props.description,
        },
        {
          name: "theme_color",
          content: "#099768",
        },
        {
          property: `og:title`,
          content: props.title,
        },
        {
          property: `google-site-verification`,
          content: "98DBtNXTfl3R1jjosdfgq-XGXkNSJxh2jbGsmq2BVfM",
        },
        {
          property: `og:description`,
          content: props.description,
        },
        {
          property: `og:image`,
          content: siteUrl + (props.image || site.siteMetadata.image),
        },
        props.index === false && {
          name: "robots",
          content: "noindex",
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.social?.twitter || ``,
        },
        {
          name: `twitter:title`,
          content: props.title,
        },
        {
          name: `twitter:description`,
          content: props.description,
        },
      ].filter(element => element)}
    >
      {props.breadcrumbs && (
        <script type="application/ld+json">
          {JSON.stringify(getBreadcrumb(props.breadcrumbs, siteUrl))}
        </script>
      )}

      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7445623624628755"
        crossOrigin="anonymous"></script>
    </Helmet>
  )
}

export default SEO
